import IPhone from 'components/iPhone';
import EmptyMessage from 'components/EmptyMessage';
import Hat from 'components/icons/hat';
import { useEffect } from 'react';

const NoAuth = () => {
    useEffect(() => {
        const message = {
            eventName: 'rt-no-auth-error',
        };
        window.parent.postMessage(message, '*');
    }, []);
    return (
        <IPhone hideYourPhoneNumber>
            <EmptyMessage
                icon={<Hat className="rainbow-m-bottom_medium" />}
                title="Authentication failed."
                description="To make sure you're getting the correct and most recent information, please refresh your browser page before continuing."
            />
        </IPhone>
    );
};

export default NoAuth;
