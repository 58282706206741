import React from 'react';
import ReactDOM from 'react-dom/client';
import { RainbowFirebaseApp } from '@rainbow-modules/app';
import { BrowserRouter } from 'react-router-dom';
import { Spinner } from 'react-rainbow-components';
import App from './app';
import { app as firebaseApp } from './firebase';
import reportWebVitals from './reportWebVitals';
import { BRAND, SUCCESS, ERROR, WARNING } from './constants';
import './styles.css';

const theme = {
    rainbow: {
        palette: {
            brand: BRAND,
            success: SUCCESS,
            warning: WARNING,
            error: ERROR,
        },
    },
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <RainbowFirebaseApp
            theme={theme}
            app={firebaseApp}
            spinner={<Spinner type="arc" variant="brand" size="x-large" />}
        >
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </RainbowFirebaseApp>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
