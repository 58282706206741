import logEvent, { WIDGET_CLOSE, WIDGET_OPEN } from 'analytics';
import { createContext, useContext, useState, useEffect } from 'react';

const context = createContext(false);

export const useOpenWidgetState = () => {
    return useContext(context);
};

export const useOpenWidgetStateListener = () => {
    const [isOpen, setOpen] = useState(true);

    useEffect(() => {
        window.addEventListener('message', ({ data }) => {
            if (data.type === 'rt-widget-open-state') {
                setOpen(Boolean(data.isOpen));
            }
        });
    }, []);

    useEffect(() => {
        if (isOpen) {
            logEvent(WIDGET_OPEN);
        } else {
            logEvent(WIDGET_CLOSE);
        }
    }, [isOpen]);

    return isOpen;
};

export default context.Provider;
