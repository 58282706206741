import Close from 'components/icons/close';
import { useNavigate } from 'react-router-dom';
import { Container, StyledCloseButtonIcon, Column } from './styled';
import { Header, Title, SearchBoxStyled, Content, Description } from '../styled';
import ReadFromAlgolia from '../readFromAlgolia';
import SearchEmptyMessage from 'components/AlgoliaSearchEmptyMessage';
import { connectStats } from 'react-instantsearch-dom';
import { StatsProvided } from 'react-instantsearch-core';

type SearchContactProps = StatsProvided;

const SearchContact = (props: SearchContactProps) => {
    const navigate = useNavigate();
    return (
        <Container>
            <Container>
                <Header>
                    <Column>
                        <Title>Contacts</Title>
                        <Description>{props.nbHits} contacts</Description>
                        <StyledCloseButtonIcon icon={<Close />} onClick={() => navigate(-1)} />
                    </Column>
                    <SearchBoxStyled autoFocus />
                </Header>
                <Content>
                    <ReadFromAlgolia noMessageInfo />
                    <SearchEmptyMessage />
                </Content>
            </Container>
        </Container>
    );
};

export default connectStats(SearchContact);
