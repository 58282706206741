import { useDoc } from '@rainbow-modules/firebase-hooks';
import { Contact } from '@transactional-message-tool/shared';

interface Params {
    appId?: string;
    groupId?: string;
    contactId?: string;
}

const useContact = ({ appId, groupId, contactId }: Params) =>
    useDoc<Contact>({
        path: `/apps/${appId}/groups/${groupId}/contacts/${contactId}`,
        flat: true,
        disabled: !appId || !groupId || !contactId,
    });

export default useContact;
