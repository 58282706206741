import { setUserId, setUserProperties } from 'analytics';
import {
    signInWithCustomToken as firebaseSignInWithCustomToken,
    UserCredential,
} from 'firebase/auth';
import { auth } from '../../../firebase';

const signInWithCustomToken = async (customToken: string): Promise<UserCredential> => {
    const credential = await firebaseSignInWithCustomToken(auth, customToken);
    setUserId(credential.user.uid);
    setUserProperties({
        displayName: credential.user.displayName,
    });
    return credential;
};

export default signInWithCustomToken;
