import { useCallback } from 'react';
import BackendClient from 'data/services/axios';

type Methods = 'get' | 'post' | 'patch' | 'put' | 'delete';

interface Params<B> {
    path: string;
    method: Methods;
    body?: B | Record<string, unknown>;
}

const makeRequest = async <B, R>({ path, method, body }: Params<B>) => {
    const response = await BackendClient[method]<R>(path, body);
    return response.data;
};

interface Props {
    method: Methods;
}

interface MutateArgs<B> {
    body?: B | Record<string, unknown>;
    path: string;
}

const useMutation = <B, R>(props: Props): ((mutateArgs: MutateArgs<B>) => Promise<R>) => {
    const { method } = props;
    return useCallback(({ body, path }) => makeRequest<B, R>({ path, method, body }), [method]);
};

export default useMutation;
