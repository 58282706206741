import PropTypes from 'prop-types';
import { IconProps } from './types';

const Edit = ({ title, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            width={20}
            height={21}
            viewBox="0 0 20 21"
            fill="none"
        >
            <title>{title}</title>
            <path
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.80129 3.96859C2.20762 3.56226 2.75872 3.33398 3.33335 3.33398H9.16669C9.44283 3.33398 9.66669 3.55784 9.66669 3.83398C9.66669 4.11013 9.44283 4.33398 9.16669 4.33398H3.33335C3.02393 4.33398 2.72719 4.4569 2.5084 4.67569C2.2896 4.89449 2.16669 5.19123 2.16669 5.50065V17.1673C2.16669 17.4767 2.2896 17.7735 2.5084 17.9923C2.72719 18.2111 3.02393 18.334 3.33335 18.334H15C15.3094 18.334 15.6062 18.2111 15.825 17.9923C16.0438 17.7735 16.1667 17.4767 16.1667 17.1673V11.334C16.1667 11.0578 16.3905 10.834 16.6667 10.834C16.9428 10.834 17.1667 11.0578 17.1667 11.334V17.1673C17.1667 17.742 16.9384 18.2931 16.5321 18.6994C16.1258 19.1057 15.5747 19.334 15 19.334H3.33335C2.75872 19.334 2.20762 19.1057 1.80129 18.6994C1.39496 18.2931 1.16669 17.742 1.16669 17.1673V5.50065C1.16669 4.92602 1.39496 4.37492 1.80129 3.96859Z"
                className="Vector (Stroke)"
            />
            <path
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.6666 2.56641C16.3304 2.56641 16.0079 2.69997 15.7702 2.93773L7.95145 10.7565L7.35382 13.147L9.74434 12.5494L17.5631 4.73062C17.8008 4.49287 17.9344 4.17041 17.9344 3.83417C17.9344 3.49794 17.8008 3.17548 17.5631 2.93773C17.3253 2.69997 17.0029 2.56641 16.6666 2.56641ZM15.0631 2.23062C15.4884 1.80533 16.0652 1.56641 16.6666 1.56641C17.2681 1.56641 17.8449 1.80533 18.2702 2.23062C18.6955 2.65591 18.9344 3.23272 18.9344 3.83417C18.9344 4.43562 18.6955 5.01244 18.2702 5.43773L10.3535 13.3544C10.2894 13.4185 10.2092 13.4639 10.1212 13.4859L6.7879 14.3192C6.61752 14.3618 6.43727 14.3119 6.31308 14.1877C6.18889 14.0635 6.13897 13.8833 6.18157 13.7129L7.0149 10.3796C7.03688 10.2917 7.08234 10.2114 7.14642 10.1473L15.0631 2.23062Z"
                className="Vector (Stroke)_2"
            />
        </svg>
    );
};

Edit.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

Edit.defaultProps = {
    className: undefined,
    style: undefined,
    title: 'Edit',
};

export default Edit;
