import PropTypes from 'prop-types';
import { IconProps } from './types';

const Message = ({ title, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            width={49}
            height={48}
            viewBox="0 0 49 48"
            fill="none"
        >
            <title>{title}</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M41.2101 7.29511C36.7529 2.83786 30.8196 0.380859 24.5061 0.380859C18.1926 0.380859 12.2594 2.83786 7.80211 7.29511C0.822613 14.2769 -1.08089 24.9914 3.02536 33.8429C3.09961 34.2074 2.89936 35.6901 2.75536 36.7746C2.21086 40.8381 1.93186 43.8216 3.31111 45.2031C4.68811 46.5824 7.66936 46.3034 11.7329 45.7566C12.8174 45.6126 14.3181 45.4191 14.5521 45.4439C17.7156 46.9086 21.0974 47.6174 24.4544 47.6174C30.6149 47.6174 36.6944 45.2279 41.2101 40.7099C50.4194 31.4961 50.4216 16.5066 41.2101 7.29511Z"
                fill="#EBE7F1"
            />
            <path
                d="M31.1276 24.9355C31.1276 26.1798 32.1469 27.1855 33.3889 27.1855C34.6309 27.1855 35.6389 26.1798 35.6389 24.9355C35.6389 23.6913 34.6309 22.6855 33.3889 22.6855H33.3686C32.1244 22.6855 31.1276 23.6913 31.1276 24.9355Z"
                fill="#6750A4"
            />
            <path
                d="M22.1074 24.9355C22.1074 26.1798 23.1244 27.1855 24.3686 27.1855C25.6106 27.1855 26.6186 26.1798 26.6186 24.9355C26.6186 23.6913 25.6106 22.6855 24.3686 22.6855H24.3484C23.1064 22.6855 22.1074 23.6913 22.1074 24.9355Z"
                fill="#6750A4"
            />
            <path
                d="M13.0894 24.9355C13.0894 26.1798 14.1064 27.1855 15.3484 27.1855C16.5904 27.1855 17.5984 26.1798 17.5984 24.9355C17.5984 23.6913 16.5904 22.6855 15.3484 22.6855H15.3281C14.0839 22.6855 13.0894 23.6913 13.0894 24.9355Z"
                fill="#6750A4"
            />
        </svg>
    );
};

Message.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

Message.defaultProps = {
    className: undefined,
    style: undefined,
    title: 'Message',
};

export default Message;
