/* eslint-disable no-debugger */
import { useEffect, useState } from 'react';
import { RenderIf, Spinner } from 'react-rainbow-components';
import { Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { showAppMessage } from '@rainbow-modules/app';
import useQueryParams from 'hooks/useQueryParams';
import Contacts from 'pages/Contacts';
import Chat from 'pages/Chat';
import signInWithCustomToken from 'data/services/auth/signInWithCustomToken';
import { useAuthState } from '@rainbow-modules/firebase-hooks';
import authenticateWidget from 'data/services/auth/authenticateWidget';
import TwilioConversationProvider from 'components/TwilioConversationProvider';
import { auth } from './firebase';
import { User } from 'firebase/auth';
import IPhone from './components/iPhone';
import SearchContact from 'pages/Contacts/Search';
import Root from 'pages/Root';
import WidgetOpenProvider, { useOpenWidgetStateListener } from 'hooks/openWidgetContext';
import NoAuth from 'pages/NoAuth';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});

const App = () => {
    const query = useQueryParams();
    const appId = query.get('appId');
    const token = query.get('token');

    const [groupId, setGroupId] = useState<string>();
    const isAuth = useAuthState();
    const [isLoading, setLoading] = useState(false);
    const isWidgetOpen = useOpenWidgetStateListener();

    useEffect(() => {
        if (appId && token && isAuth !== undefined) {
            if (isAuth && groupId === undefined) {
                (async () => {
                    const { claims } = await (auth.currentUser as User).getIdTokenResult();
                    if (claims.appId !== appId) {
                        setLoading(true);
                        return await auth.signOut();
                    }
                    setGroupId(claims.group);
                })();
            } else {
                (async () => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setLoading(true);
                    const { customToken, tenantId } = await authenticateWidget({
                        appId,
                        token,
                    });
                    auth.tenantId = tenantId;
                    try {
                        await signInWithCustomToken(customToken);
                    } catch (error) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        showAppMessage({
                            message: (error as Error).message,
                            variant: 'error',
                        });
                    }
                    setLoading(false);
                })();
            }
        }
    }, [appId, token, isAuth, groupId]);

    if (isAuth && groupId) {
        return (
            <QueryClientProvider client={queryClient}>
                <TwilioConversationProvider>
                    <WidgetOpenProvider value={isWidgetOpen}>
                        <IPhone>
                            <RenderIf isTrue={!isLoading}>
                                <Routes>
                                    <Route
                                        path="/"
                                        element={
                                            <Navigate
                                                to={`/app/${appId}/groups/${groupId}/contacts`}
                                            />
                                        }
                                    />
                                    <Route path="/app/:appId/groups/:groupId" element={<Root />}>
                                        <Route path="contacts" element={<Contacts />}>
                                            <Route
                                                path="search"
                                                element={<SearchContact />}
                                            ></Route>
                                        </Route>
                                        <Route path="contacts/:contactId/chat" element={<Chat />} />
                                    </Route>
                                </Routes>
                            </RenderIf>
                            <Spinner
                                isVisible={isLoading}
                                type="arc"
                                size="large"
                                variant="brand"
                            />
                        </IPhone>
                    </WidgetOpenProvider>
                </TwilioConversationProvider>
            </QueryClientProvider>
        );
    }
    if (isAuth === false && !isLoading) {
        return <NoAuth />;
    }
    return null;
};

export default App;
