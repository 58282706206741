import { createContext, ReactNode, useEffect, useRef } from 'react';
import { Client } from '@twilio/conversations';
import useMutation from 'data/hooks/useMutation';

export interface ContextValue {
    client?: Client;
}

export const Context = createContext<ContextValue>({
    client: undefined,
});

interface Props {
    children: ReactNode;
}

const TwilioConversationProvider = ({ children }: Props) => {
    const client = useRef<Client>();
    const fetchToken = useMutation<undefined, { accessToken: string }>({
        method: 'get',
    });

    useEffect(() => {
        (async () => {
            const { accessToken } = await fetchToken({ path: '/chat/auth' });
            client.current = new Client(accessToken);
            client.current?.on('tokenAboutToExpire', async () => {
                const { accessToken } = await fetchToken({ path: '/chat/auth' });
                client.current = await client.current?.updateToken(accessToken);
            });
        })();
        () => {
            return client.current?.shutdown();
        };
    }, [fetchToken]);

    const contextValue = {
        client: client.current,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export default TwilioConversationProvider;
