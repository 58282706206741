import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Avatar, Textarea, ButtonIcon } from 'react-rainbow-components';
import { ChevronLeft } from '@rainbow-modules/icons';
import { BACKGROUND_BRAND } from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
`;

export const Header = styled.div`
    display: flex;
    background-color: #fff;
    align-items: center;
    padding: 8px 12px 12px 8px;
    border-bottom: 1px solid #f5f3f3;
`;

export const HeaderLink = styled(Link)`
    display: flex;

    :hover,
    :active {
        text-decoration: none;
    }
`;

export const ArrowIcon = styled(ChevronLeft)`
    margin-right: 12px;
    margin-top: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    width: 12px;
    height: 12px;
`;

export const StyledAvatar = styled(Avatar)`
    margin-right: 12px;
    flex-shrink: 0;
    align-self: top;
`;

export const ContactName = styled.h1`
    font-size: 15px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const ContactPhoneNumber = styled.h2`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const Content = styled.div`
    height: 100%;
    background-color: ${BACKGROUND_BRAND};
    flex-grow: 1;
    display: flex;
    box-sizing: border-box;
    overflow: auto;
    position: relative;
`;

export const BottomBar = styled.footer`
    background-color: #fff;
    padding: 16px 16px 24px 16px;
    border-top: 1px solid #f5f3f3;
    position: relative;
`;

export const StyledSearchInput = styled(Textarea)`
    width: 100%;
    max-height: 460px;
    min-height: 40px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }

    textarea {
        max-height: 420px;
    }
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    position: absolute;
    width: 37px;
    height: 37px;
    right: 20px;
    bottom: 28px;
`;

export const LoadingContainer = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 100px;
    align-self: flex-end;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
`;
