import { forwardRef, useState, RefObject, useEffect } from 'react';
import { Paginator, Message } from '@twilio/conversations';
import { VirtuosoHandle } from 'react-virtuoso';
import { RenderIf } from 'react-rainbow-components';
import formatDate from 'helpers/formatDate';
import { StyledVirtuoso, ItemContainer, Space, StyledDateBadge, StyledUnreadDot } from './styled';
import MessageBubble from './Message';
import DateBadge from './dateBadge';
import useGroupMessages from './hooks/useGroupMessages';
import usePrependItems from './hooks/usePrependItems';
import useShowScrollDownButton from './hooks/useShowScrollDownButton';
import { CHAT_PAGE_SIZE } from '../../constants';
import { EmptyMessage, StyledDownButton, StyledChevronDown } from './styled';
import updateNewMessageStatus from 'data/services/conversation/updateNewMessageStatus';
import { useParams } from 'react-router-dom';
import useContact from 'data/hooks/useContact';
import { useOpenWidgetState } from 'hooks/openWidgetContext';

interface ChatProps {
    messages?: Paginator<Message>;
    messagesCount: number;
}

const Chat = ({ messages, messagesCount }: ChatProps, ref: RefObject<VirtuosoHandle>) => {
    const { appId, groupId, contactId } = useParams();
    const [atBottom, setAtBottom] = useState(false);
    const [isScrolling, setScrolling] = useState(false);
    const [firstItemDate, setFirstItemDate] = useState<Date | null>();
    const groupedMessages = useGroupMessages(messages);
    const showButton = useShowScrollDownButton(atBottom);
    const { firstItemIndex, prependItems } = usePrependItems(messagesCount);

    const isWidgetOpen = useOpenWidgetState();
    const followOutput = isWidgetOpen ? 'smooth' : false;

    const [contact] = useContact({
        appId: appId as string,
        groupId: groupId as string,
        contactId: contactId as string,
    });

    useEffect(() => {
        if (contact?.hasNewMessage && atBottom) {
            updateNewMessageStatus({
                appId: appId as string,
                groupId: groupId as string,
                contactId: contactId as string,
                hasNewMessage: false,
            });
        }
    }, [appId, atBottom, contact?.hasNewMessage, contactId, groupId]);

    const atBottomStateChange = async (isAtBottom: boolean) => {
        setAtBottom(isAtBottom);
    };

    return (
        <>
            <StyledVirtuoso
                ref={ref}
                firstItemIndex={firstItemIndex}
                initialTopMostItemIndex={CHAT_PAGE_SIZE - 1}
                data={groupedMessages}
                startReached={prependItems}
                atBottomStateChange={atBottomStateChange}
                isScrolling={setScrolling}
                rangeChanged={(range) =>
                    setFirstItemDate(
                        messages?.items?.[range.startIndex - firstItemIndex]?.dateCreated,
                    )
                }
                components={{
                    EmptyPlaceholder: () => (
                        <EmptyMessage>
                            <p>No messages here yet...</p>
                            <p>Send a message by typing something in the field below.</p>
                        </EmptyMessage>
                    ),
                }}
                itemContent={(_, msg) => {
                    if (msg.showGroupDate) {
                        return (
                            <ItemContainer key={msg.sid}>
                                <Space />
                                <DateBadge label={formatDate(msg.dateCreated)} />
                                <Space />
                                <MessageBubble message={msg} />
                            </ItemContainer>
                        );
                    }
                    return <MessageBubble key={msg.sid} message={msg} />;
                }}
                followOutput={followOutput}
            />
            <StyledDateBadge label={formatDate(firstItemDate)} isScrolling={isScrolling} />
            <RenderIf isTrue={showButton}>
                <StyledDownButton
                    onClick={() => {
                        if (messages?.items) {
                            ref?.current?.scrollToIndex({
                                index: messages.items.length - 1,
                                behavior: 'smooth',
                            });
                        }
                    }}
                    style={{
                        position: 'absolute',
                        right: 0,
                        bottom: 55,
                        zIndex: 100,
                    }}
                >
                    <RenderIf isTrue={contact?.hasNewMessage && !atBottom}>
                        <StyledUnreadDot />
                    </RenderIf>
                    <StyledChevronDown />
                </StyledDownButton>
            </RenderIf>
        </>
    );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default forwardRef(Chat);
