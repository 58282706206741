import styled from 'styled-components';
import { Message } from './types';
import { Virtuoso } from 'react-virtuoso';
import { Badge } from 'react-rainbow-components';
import { ChevronDown } from '@rainbow-modules/icons';
import DateBadge from './dateBadge';
import { BACKGROUND_BRAND } from '../../constants';

export const StyledVirtuoso = styled(Virtuoso<Message>)`
    width: 100%;
    overflow-x: hidden;
`;

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Space = styled.div`
    height: 12px;
`;

export const StyledDateBadge = styled(DateBadge)<{ isScrolling: boolean }>`
    position: absolute;
    top: 12px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: fit-content;
    opacity: ${(props) => (props.isScrolling ? 1 : 0)};
    visibility: ${(props) => (props.isScrolling ? 'visible' : 'hidden')};
    transition: visibility 1000ms linear, opacity 500ms linear;
`;

export const StyledBadge = styled(Badge)`
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    text-transform: none;
    align-self: center;
    box-sizing: border-box;
    flex-shrink: 0;
`;

interface ItemProps {
    direction: 'outgoing' | 'incoming';
}

export const Item = styled.div<ItemProps>`
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2rem;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    position: relative;
    word-break: break-word;
    overflow-wrap: break-word;
    box-sizing: border-box;
    white-space: pre-line;
    clear: both;
    background-color: #c1a5eb;
    padding: 6px 12px 4px 13px;
    border-radius: 10px;
    animation: 0.3s ease 0s 1 normal both running fadeInLeft;
    min-height: 34px;
    max-width: 80%;
    width: fit-content;

    ::before {
        content: '';
        position: absolute;
        z-index: 0;
        bottom: 0;
        height: 20px;
        width: 20px;
    }

    ::after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        width: 10px;
        height: 20px;
    }

    ${(props) =>
        props.direction === 'incoming' &&
        `
            ::before {
                left: -7px;
                background: #c1a5eb;
                border-bottom-right-radius: 15px;
            }

            ::after {
                left: -10px;
                background: ${BACKGROUND_BRAND};
                border-bottom-right-radius: 10px;
            }
        `};

    ${(props) =>
        props.direction === 'outgoing' &&
        `
            background-color: #e5e3e8;
            align-self: flex-end;

            ::before {
                right: -7px;
                background-color: #e5e3e8;
                border-bottom-left-radius: 15px;
            }

            ::after {
                right: -10px;
                background: ${BACKGROUND_BRAND};
                border-bottom-left-radius: 10px;
            }
        `};
`;

export const MessageText = styled.p`
    padding-bottom: 4px;
`;

export const AuthorContainer = styled.div`
    padding: 6px 6px 6px 0;
    display: flex;
    justify-content: flex-end;
    gap: 6px;
`;

export const AuthorName = styled.p`
    text-align: end;
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const Time = styled.h3`
    font-size: 10px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    opacity: 0.6;
    text-align: end;
`;

export const MessageContainer = styled.div`
    padding: 0 16px;
    display: flex;
    flex-direction: column;

    :last-of-type {
        padding-bottom: 16px;
    }
`;

export const EmptyMessage = styled.div`
    background-color: ${(props) => props.theme.rainbow.palette.brand.light};
    border-radius: 10px;
    font-size: 12px;
    width: 75%;
    margin: 20px auto 0 auto;
    padding: 8px 12px;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
`;

export const StyledDownButton = styled.button`
    background-color: white;
    border: none;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px 0 0 8px;
    height: 40px;
    width: 40px;
`;

export const StyledChevronDown = styled(ChevronDown)`
    color: ${(props) => props.theme.rainbow.palette.brand.main};
    width: 20px;
    height: 9px;
`;

export const StyledUnreadDot = styled.div`
    background-color: ${(props) => props.theme.rainbow.palette.brand.main};
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    top: -5px;
    left: -5px;
`;

export const StyledImage = styled.img`
    border-radius: 6px;
    margin: 5px 0;
`;
