import { useMemo } from 'react';
import { Paginator, Message } from '@twilio/conversations';
import isSameDay from 'helpers/isSameDay';
import { Message as ItemMessage } from '../types';

const splitAuthor = (author: string | null) => {
    if (author) {
        return author.split(':');
    }
    return [];
};

const useGroupMessages = (messages?: Paginator<Message>) => {
    return useMemo(
        () =>
            messages?.items.map((item, index) => {
                const { author, sid, body, dateCreated, type, attachedMedia } = item;
                const [, authorName] = splitAuthor(author);
                const newItem: ItemMessage = {
                    sid,
                    body,
                    dateCreated,
                    type,
                    attachedMedia,
                    direction: /^\+\d+$/.test(author as string) ? 'incoming' : 'outgoing',
                    showGroupDate: false,
                };
                if (item.author !== messages?.items?.[index + 1]?.author) {
                    newItem.authorName = authorName;
                }
                if (!isSameDay(item.dateCreated, messages?.items?.[index - 1]?.dateCreated)) {
                    newItem.showGroupDate = true;
                }
                return newItem;
            }),
        [messages?.items],
    );
};

export default useGroupMessages;
