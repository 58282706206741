import { useRef, useEffect, useState, KeyboardEvent } from 'react';
import { useParams } from 'react-router-dom';
import { Conversation, Paginator, Message } from '@twilio/conversations';
import { VirtuosoHandle } from 'react-virtuoso';
import { RenderIf, Spinner } from 'react-rainbow-components';
import { UpDirection } from '@rainbow-modules/icons';
import { useQuery, useQueryClient } from 'react-query';
import Header from './header';
import Chat from 'components/Chat';
import useMutation from 'data/hooks/useMutation';
import useTwilioConversation from 'hooks/useTwilioConversation';
import {
    Container,
    Content,
    BottomBar,
    StyledSearchInput,
    StyledButtonIcon,
    LoadingContainer,
} from './styled';
import { CHAT_PAGE_SIZE } from '../../constants';
import logEvent, { CONVERSATION_CLOSE, CONVERSATION_OPEN, MESSAGE_SEND } from 'analytics';

const useConversation = () => {
    const { appId, groupId, contactId } = useParams();
    const { client } = useTwilioConversation();

    const addChatParticipant = useMutation<undefined, { conversationId: string }>({
        method: 'post',
    });

    return useQuery<unknown, unknown, Conversation>(
        ['conversation', contactId],
        async () => {
            const { conversationId } = await addChatParticipant({
                path: `/apps/${appId}/groups/${groupId}/contacts/${contactId}/chat/participants`,
            });
            return client?.peekConversationBySid(conversationId);
        },
        { cacheTime: 0 },
    );
};

const useMessagesCount = (conversation?: Conversation) => {
    const { contactId } = useParams();
    return useQuery<unknown, unknown, number>(
        ['messages-count', contactId],
        () => conversation?.getMessagesCount(),
        {
            enabled: Boolean(conversation),
            cacheTime: 0,
        },
    );
};

const useMessages = (conversation?: Conversation) => {
    const { contactId } = useParams();

    return useQuery<unknown, unknown, Paginator<Message>>(
        ['messages', contactId],
        () => conversation?.getMessages(CHAT_PAGE_SIZE),
        {
            enabled: Boolean(conversation),
            cacheTime: 0,
        },
    );
};

const ChatPage = () => {
    const { contactId } = useParams();
    const inputRef = useRef();
    const virtuosoRef = useRef<VirtuosoHandle>(null);
    const [value, setValue] = useState('');
    const queryClient = useQueryClient();
    const { data: conversation, isLoading: isLoadingConversation } = useConversation();
    const { data: messagesCount = 0, isLoading: isLoadingMessagesCount } =
        useMessagesCount(conversation);
    const { data: messages, isLoading: isLoadingMessages } = useMessages(conversation);
    const isLoading = isLoadingConversation || isLoadingMessagesCount || isLoadingMessages;
    const hasValue = /\S/.test(value);

    useEffect(() => {
        if (conversation) {
            conversation.on('messageAdded', (newMessage) => {
                queryClient.setQueryData<Paginator<Message> | undefined>(
                    ['messages', contactId],
                    (oldData) => {
                        if (oldData) {
                            return {
                                ...oldData,
                                items: [...oldData.items, newMessage],
                            };
                        }
                    },
                );

                logEvent(MESSAGE_SEND);
            });
        }
        return () => {
            if (conversation) {
                conversation.removeAllListeners();
            }
        };
    }, [contactId, conversation, queryClient]);

    // return focus to input after send a message
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inputRef?.current?.focus();
    }, [messages?.items.length]);

    useEffect(() => {
        logEvent(CONVERSATION_OPEN);
        return () => {
            logEvent(CONVERSATION_CLOSE);
        };
    }, [contactId]);

    const submit = () => {
        conversation?.sendMessage(value);
        setValue('');
        if (messages?.items) {
            virtuosoRef?.current?.scrollToIndex({
                index: messages.items.length,
                behavior: 'smooth',
            });
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (hasValue) {
                submit();
            }
        }
    };
    return (
        <Container>
            <Header />
            <Content>
                <RenderIf isTrue={isLoading}>
                    <LoadingContainer>
                        <Spinner variant="brand" type="arc" />
                    </LoadingContainer>
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    <Chat messages={messages} messagesCount={messagesCount} ref={virtuosoRef} />
                </RenderIf>
            </Content>
            <BottomBar>
                <StyledSearchInput
                    placeholder="Type something"
                    onChange={(event) => setValue(event.target.value)}
                    onKeyDown={handleKeyDown}
                    value={value}
                    rows={1}
                    grow
                    key={messages?.items.length}
                    ref={inputRef}
                    disabled={isLoading}
                />
                <StyledButtonIcon
                    variant="brand"
                    borderRadius="semi-rounded"
                    icon={<UpDirection />}
                    disabled={!hasValue || isLoading}
                    onClick={submit}
                />
            </BottomBar>
        </Container>
    );
};

export default ChatPage;
