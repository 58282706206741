import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

interface updateNewMessageStatusI {
    appId: string;
    groupId: string;
    contactId: string;
    hasNewMessage?: boolean;
}
const updateNewMessageStatus = (props: updateNewMessageStatusI) => {
    const { appId, groupId, contactId, hasNewMessage = false } = props;
    const ref = doc(db, `/apps/${appId}/groups/${groupId}/contacts/${contactId}`);
    return updateDoc(ref, {
        hasNewMessage,
    });
};

export default updateNewMessageStatus;
