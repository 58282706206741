import { Media } from '@transactional-message-tool/shared';

interface MediaMessage {
    lastMessage?: string;
    contentType?: 'image' | 'video' | 'audio' | 'unknown';
}

const getMediaMessage = (media?: Media[]) => {
    if (Array.isArray(media) && media.length > 0) {
        const obj = media[0];
        const mediaMessageObj: MediaMessage = {};
        if (/^image\/.+/.test(obj.ContentType)) {
            mediaMessageObj['lastMessage'] = `Photo: ${obj.Filename}`;
            mediaMessageObj['contentType'] = 'image';
        } else if (/^video\/.+/.test(obj.ContentType)) {
            mediaMessageObj['lastMessage'] = `Video: ${obj.Filename}`;
            mediaMessageObj['contentType'] = 'video';
        } else if (/^audio\/.+/.test(obj.ContentType)) {
            mediaMessageObj['lastMessage'] = `Audio: ${obj.Filename}`;
            mediaMessageObj['contentType'] = 'audio';
        }
        return mediaMessageObj;
    }
    return { lastMessage: `media: unknown`, contentType: 'unknown' };
};

export default getMediaMessage;
