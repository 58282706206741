const isSameDay = (date1?: Date | null, date2?: Date | null) => {
    try {
        return Boolean(
            date1 &&
                date2 &&
                date1.getDate() === date2.getDate() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getFullYear() === date2.getFullYear(),
        );
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
    }
};

export default isSameDay;
