import PropTypes from 'prop-types';
import { StyledBadge } from './styled';

interface Props {
    style?: React.CSSProperties;
    className?: string;
    label?: string;
}

const DateBadge = (props: Props) => {
    const { className, style, label } = props;
    return (
        <StyledBadge
            label={label}
            className={className}
            style={style}
            borderRadius="semi-rounded"
        />
    );
};

DateBadge.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.string,
};

DateBadge.defaultProps = {
    className: '',
    style: {},
    label: undefined,
};

export default DateBadge;
