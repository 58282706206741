import PropTypes from 'prop-types';
import { IconProps } from './types';

const Contacts = ({ title, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            width={83}
            height={68}
            viewBox="0 0 83 68"
            fill="none"
        >
            <title>{title}</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M62.9826 30.2287H63.0493C70.1493 30.2287 75.9493 24.4287 75.9493 17.362C75.9493 10.262 70.1493 4.462 63.0493 4.462C61.6493 4.462 60.316 4.69533 59.0493 5.09533C61.3493 8.49533 62.6826 12.5953 62.6826 17.0287C62.716 21.5287 61.316 25.862 58.6826 29.462C60.0493 29.962 61.4826 30.2287 62.9826 30.2287Z"
                fill="#EBE7F1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.916 30.2287H20.016C21.516 30.2287 22.9493 29.962 24.316 29.462C21.7826 25.9953 20.2827 21.6953 20.2827 17.0287C20.2827 12.5953 21.616 8.49533 23.916 5.09533C22.6493 4.69533 21.316 4.462 19.916 4.462C12.816 4.462 7.04932 10.262 7.04932 17.362C7.04932 24.4287 12.816 30.2287 19.916 30.2287Z"
                fill="#EBE7F1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M41.4846 33.2489H41.5946C45.9113 33.2356 49.9646 31.5389 53.0046 28.4756C56.048 25.4123 57.7146 21.3489 57.698 17.0389C57.698 8.0956 50.428 0.822266 41.4846 0.822266C32.5446 0.822266 25.2746 8.0956 25.2746 17.0389C25.2746 25.9756 32.5446 33.2489 41.4846 33.2489Z"
                fill="#EBE7F1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M63.083 35.0273C59.283 35.0273 55.7163 35.794 52.7163 37.094C63.2163 40.1273 70.6497 47.394 70.8497 55.894C76.3163 55.194 82.2163 53.1273 82.2163 47.494C82.2163 40.7273 73.4497 35.0273 63.083 35.0273Z"
                fill="#EBE7F1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.2496 37.0607C27.2496 35.794 23.683 35.0273 19.9163 35.0273C9.54963 35.0273 0.782959 40.7273 0.782959 47.494C0.782959 53.1273 6.64963 55.1607 12.1163 55.894C12.283 47.394 19.7163 40.1273 30.2496 37.0607Z"
                fill="#EBE7F1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M41.4817 40.4841C28.2783 40.4841 17.1217 47.6708 17.1217 56.1774C17.1217 67.1774 35.455 67.1774 41.4817 67.1774C51.9417 67.1774 65.8483 66.0441 65.8483 56.2474C65.8483 47.7008 54.6883 40.4841 41.4817 40.4841Z"
                fill="#EBE7F1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M41.4809 40.4844C28.2775 40.4844 17.1208 47.671 17.1208 56.1777C17.1208 67.1777 35.4542 67.1777 41.4809 67.1777C51.9409 67.1777 65.8475 66.0444 65.8475 56.2477C65.8475 47.701 54.6875 40.4844 41.4809 40.4844Z"
                fill="#6750A4"
            />
            <path
                d="M41.5951 33.2489H41.4851C32.5451 33.2489 25.2751 25.9756 25.2751 17.0389C25.2751 8.0956 32.5451 0.822266 41.4851 0.822266C50.4285 0.822266 57.6985 8.0956 57.6985 17.0389C57.7152 21.3489 56.0485 25.4123 53.0051 28.4756C49.9651 31.5389 45.9118 33.2356 41.5951 33.2489Z"
                fill="#6750A4"
            />
        </svg>
    );
};

Contacts.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

Contacts.defaultProps = {
    className: undefined,
    style: undefined,
    title: 'Contacts',
};

export default Contacts;
