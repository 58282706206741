import { useCollection } from '@rainbow-modules/firebase-hooks';
import { query, orderBy, limit } from 'firebase/firestore';
import { Contact } from '@transactional-message-tool/shared';
import { useParams } from 'react-router-dom';
import ContactItem from './contact';
import { Spinner } from 'react-rainbow-components';
import EmptyMessage from '../../components/EmptyMessage';
import { MessageIcon } from './styled';
import getInitials from 'helpers/getInitials';
import getMediaMessage from 'helpers/getMediaMessage';

const ReadFromFirestore = () => {
    const { appId, groupId } = useParams();
    const [data, isLoading] = useCollection<{ id: string; data: Contact }>({
        path: `/apps/${appId}/groups/${groupId}/contacts`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        query: (ref) => query(ref, orderBy('lastMessage.createdAt', 'desc'), limit(20)),
    });
    const contacts = data.map((doc) => {
        const name = `${doc.data.firstName} ${doc.data.lastName}`;
        const lastMessage = doc.data.lastMessage?.text
            ? doc.data.lastMessage?.text
            : getMediaMessage(doc.data.lastMessage?.media)?.lastMessage;
        const contentType = doc.data.lastMessage?.media
            ? getMediaMessage(doc.data.lastMessage?.media)?.contentType
            : undefined;

        return (
            <ContactItem
                contactId={doc.id}
                name={name}
                initials={getInitials(name)}
                phoneNumber={doc.data.phoneNumber}
                unreadMessage={doc.data.hasNewMessage}
                lastMessageDate={doc.data.lastMessage?.createdAt.toDate()}
                lastMessage={lastMessage}
                contentType={contentType}
                key={doc.id}
            />
        );
    });
    if (contacts.length > 0) {
        return <div>{contacts}</div>;
    } else if (!isLoading) {
        return (
            <div>
                <EmptyMessage
                    className="rainbow-m-top_x-large rainbow-p-top_x-large rainbow-m-horizontal_large"
                    icon={<MessageIcon />}
                    title="Say hello to your users!"
                    description="Pick a person from your contact list and start your conversation."
                />
            </div>
        );
    } else {
        return (
            <span>
                <Spinner variant="brand" type="arc" />
            </span>
        );
    }
};
export default ReadFromFirestore;
