import { RenderIf } from 'react-rainbow-components';
import { useParams } from 'react-router-dom';
import {
    ContactItemContainer,
    StyledAvatar,
    ContactContent,
    ContactMainInfo,
    ContactName,
    ContactPhoneNumber,
    LastMessage,
    ChevronIcon,
    Dot,
    ChevronIconOnContact,
    StyledDate,
    StyledIcon,
    StyledContentTypeContainer,
} from './styled';
import { ReactElement } from 'react';
import FormattedRelative from 'components/FormattedRelative';
import Video from 'components/icons/video';
import Microphone from 'components/icons/microphone';

interface Props {
    contactId: string;
    name: ReactElement | string;
    initials: string;
    phoneNumber: ReactElement | string;
    lastMessage?: string;
    lastMessageDate?: Date;
    unreadMessage?: boolean;
    contentType?: string;
}

interface ContentTypeIconProps {
    contentType?: string;
}

const ContentTypeIcon = (props: ContentTypeIconProps) => {
    const { contentType } = props;
    return (
        <>
            <RenderIf isTrue={contentType === 'image'}>
                <StyledIcon />
            </RenderIf>
            <RenderIf isTrue={contentType === 'video'}>
                <StyledIcon as={Video} />
            </RenderIf>
            <RenderIf isTrue={contentType === 'audio'}>
                <StyledIcon as={Microphone} />
            </RenderIf>
        </>
    );
};

const Contact = (props: Props) => {
    const {
        contactId,
        name,
        phoneNumber,
        lastMessage,
        lastMessageDate,
        unreadMessage,
        initials,
        contentType,
    } = props;
    const { appId, groupId } = useParams();

    return (
        <ContactItemContainer to={`/app/${appId}/groups/${groupId}/contacts/${contactId}/chat`}>
            <RenderIf isTrue={unreadMessage}>
                <Dot />
            </RenderIf>
            <StyledAvatar initials={initials} />
            <ContactContent>
                <ContactMainInfo className="rainbow-m-bottom_xx-small">
                    <div className="rainbow-m-right_x-small">
                        <ContactName>{name}</ContactName>
                        <ContactPhoneNumber>{phoneNumber}</ContactPhoneNumber>
                    </div>
                    <StyledDate unreadMessage={unreadMessage}>
                        <RenderIf isTrue={lastMessageDate}>
                            <FormattedRelative value={lastMessageDate as Date} />
                        </RenderIf>
                    </StyledDate>
                </ContactMainInfo>
                <ContactMainInfo>
                    <StyledContentTypeContainer>
                        <RenderIf isTrue={contentType}>
                            <ContentTypeIcon contentType={contentType} />
                        </RenderIf>
                        <LastMessage unreadMessage={unreadMessage}>{lastMessage}</LastMessage>
                    </StyledContentTypeContainer>
                    <RenderIf isTrue={lastMessage}>
                        <ChevronIcon />
                    </RenderIf>
                </ContactMainInfo>
                <RenderIf isTrue={!lastMessage}>
                    <ChevronIconOnContact />
                </RenderIf>
            </ContactContent>
        </ContactItemContainer>
    );
};

export default Contact;
