import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Input, Avatar, ButtonIcon } from 'react-rainbow-components';
import { ChevronRight } from '@rainbow-modules/icons';
import { SearchBox } from 'react-instantsearch-dom';
import { Highlight } from 'react-instantsearch-dom';
import Message from 'components/icons/message';
import Camera from 'components/icons/camera';

export const SearchBoxStyled = styled(SearchBox)`
    input {
        width: 100%;
        box-sizing: border-box;
        background: #fff;
        border: 1px solid ${(props) => props.theme.rainbow.palette.border.disabled};
        border-radius: 40px;
        padding: 9px 15px;
        margin-bottom: 12px;
        margin-top: 8px;
        color: ${(props) => props.theme.rainbow.palette.text.main};

        ::placeholder {
            color: ${(props) => props.theme.rainbow.palette.text.header};
        }

        &:focus {
            border: 2px solid ${(props) => props.theme.rainbow.palette.brand.main};
            padding: 8px 14px;
            outline: none;
        }
    }

    button {
        display: none;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
`;

export const Header = styled.header`
    padding: 0 16px;
    border-bottom: 1px solid ${(props) => props.theme.rainbow.palette.border.disabled};
    width: 100%;
`;

export const Title = styled.h1`
    font-size: 22px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const Description = styled.h3`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    margin: 2px 0 12px 0;
`;

export const StyledInput = styled(Input)`
    width: 100%;
    margin-bottom: 12px;
`;

export const ContactItemContainer = styled(Link)`
    display: flex;
    flex-grow: 1;
    padding: 16px 0 0 16px;
    box-sizing: border-box;
    position: relative;
    border-bottom: 1px solid ${(props) => props.theme.rainbow.palette.border.disabled};

    /* :last-of-type {
        border-bottom: none;
        padding-bottom: 16px;
    } */

    :hover,
    :active {
        text-decoration: none;
        background-color: ${(props) => props.theme.rainbow.palette.border.disabled};
    }
`;

export const StyledAvatar = styled(Avatar)`
    margin-right: 12px;
    flex-shrink: 0;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
`;

export const ContactContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 8px 12px 0;
`;

export const ContactMainInfo = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
`;

export const ContactName = styled.p`
    font-size: 14px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const ContactPhoneNumber = styled.p`
    font-size: 13px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

interface ItemProps {
    unreadMessage?: boolean;
}

export const LastMessage = styled.p<ItemProps>`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-height: 3.2em;
    line-height: 1.5em;

    ${(props) =>
        props.unreadMessage &&
        `
            color: ${props.theme.rainbow.palette.text.main};
        `};
`;

export const StyledDate = styled.p<ItemProps>`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-left: 8px;
    white-space: nowrap;

    ${(props) =>
        props.unreadMessage &&
        `
            color: ${props.theme.rainbow.palette.text.main};
        `};
`;

export const ChevronIcon = styled(ChevronRight)`
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-left: 8px;
    flex-shrink: 0;
`;

export const Dot = styled.div`
    width: 8px;
    height: 8px;
    background-color: #4280ef;
    position: absolute;
    left: 4px;
    top: 34px;
    border-radius: 8px;
`;

export const StyledHighlight = styled(Highlight)`
    .ais-Highlight-highlighted {
        color: #003dff;
        background-color: rgba(30, 89, 255, 0.1);
        font-style: normal;
        font-family: Lato Bold, Helvetica, sans-serif;
    }
`;

export const ChevronIconOnContact = styled(ChevronIcon)`
    position: absolute;
    top: 30px;
    right: 8px;
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    margin-top: 2px;
    margin-right: -8px;

    > svg {
        color: ${(props) => props.theme.rainbow.palette.brand.main};
    }
`;

export const MessageIcon = styled(Message)`
    margin-bottom: 12px;
    height: 48px;
    width: 48px;
    flex-shrink: 0;
`;

export const StyledIcon = styled(Camera)`
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-right: 8px;
`;

export const StyledContentTypeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`;
