import { CSSProperties } from 'react';

interface FormattedRelativeI {
    value: Date;
    className?: string;
    style?: CSSProperties;
}
const formatDate = new Intl.DateTimeFormat('en-US').format;
const formatTime = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' }).format;

const isToday = (date: Date) => formatDate(date) === formatDate(new Date());
const isYesterday = (date: Date) => {
    const today = new Date().getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    return formatDate(date) === formatDate(new Date(today - oneDay));
};

const FormattedRelative = ({ value, className, style }: FormattedRelativeI) => {
    const date = isToday(value)
        ? formatTime(value)
        : isYesterday(value)
        ? 'Yesterday'
        : formatDate(value);
    return (
        <span className={className} style={style}>
            {date}
        </span>
    );
};

export default FormattedRelative;
