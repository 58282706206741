import { useEffect, useState } from 'react';
import BackendClient from 'data/services/axios';
import algoliasearch, { SearchClient } from 'algoliasearch/lite';

const useAlgoliaClient = () => {
    const [client, setClient] = useState<SearchClient>();
    useEffect(() => {
        (async () => {
            const res = await BackendClient.get('/contacts/search/auth');
            const { algoliaSecureKey } = res.data;
            setClient(
                algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID as string, algoliaSecureKey),
            );
        })();
    }, []);
    return client;
};

export default useAlgoliaClient;
