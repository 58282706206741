const getInitials = (name?: string | null) => {
    if (typeof name === 'string') {
        const [firstName, lastName] = name.split(/\s/).filter((word) => word !== '');
        if (firstName && lastName) {
            return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
        } else if (firstName) {
            return firstName[0].toUpperCase();
        } else if (lastName) {
            return lastName[0].toUpperCase();
        }
    }
    return 'A';
};

export default getInitials;
