import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const Title = styled.h3`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-bottom: 8px;
    text-align: center;
`;

export const Description = styled.p`
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    font-family: Lato, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    max-width: 600px;
    margin-bottom: 24px;
    padding: 0 15px;
`;
