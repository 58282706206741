import { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Paginator, Message } from '@twilio/conversations';

const usePrependItems = (messagesCount: number) => {
    const { contactId } = useParams();
    const queryClient = useQueryClient();
    const [firstItemIndex, setFirstItemIndex] = useState(messagesCount);

    const prependItems = useCallback(async () => {
        const data = queryClient.getQueryData<Paginator<Message>>(['messages', contactId]);
        if (data?.hasPrevPage) {
            const result = await data.prevPage();
            setFirstItemIndex((prevIndex) => prevIndex - result.items.length);
            queryClient.setQueryData<Paginator<Message> | undefined>(
                ['messages', contactId],
                (oldData) => {
                    if (oldData) {
                        return {
                            ...result,
                            items: [...result.items, ...oldData.items],
                        };
                    }
                },
            );
        }
    }, [queryClient, contactId]);

    return useMemo(
        () => ({
            prependItems,
            firstItemIndex,
        }),
        [firstItemIndex, prependItems],
    );
};

export default usePrependItems;
