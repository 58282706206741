import PropTypes from 'prop-types';
import { IconProps } from './types';

const Video = ({ title, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
        >
            <title>{title}</title>
            <path
                d="M17.25 5.25L12 9L17.25 12.75V5.25Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.5 3.75H2.25C1.42157 3.75 0.75 4.42157 0.75 5.25V12.75C0.75 13.5784 1.42157 14.25 2.25 14.25H10.5C11.3284 14.25 12 13.5784 12 12.75V5.25C12 4.42157 11.3284 3.75 10.5 3.75Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

Video.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

Video.defaultProps = {
    className: undefined,
    style: undefined,
    title: 'Video',
};

export default Video;
