import useMyGroup from 'data/hooks/useMyGroup';
import { RenderIf } from 'react-rainbow-components';
import { Container, NumberContainer, Number, Label } from './styled';

interface TopBarProps {
    hideYourPhoneNumber?: boolean;
}

const TopBar = ({ hideYourPhoneNumber }: TopBarProps) => {
    const { data } = useMyGroup();
    return (
        <Container>
            <RenderIf isTrue={!hideYourPhoneNumber}>
                <NumberContainer>
                    <Label>Your phone number</Label>
                    <Number>{data?.reservedNumber.friendlyName}</Number>
                </NumberContainer>
            </RenderIf>
        </Container>
    );
};

export default TopBar;
