import { Hit, BasicDoc } from 'react-instantsearch-core';
import { useDoc } from '@rainbow-modules/firebase-hooks';
import ContactItem from './contact';
import { Hits } from 'react-instantsearch-dom';
import { StyledHighlight } from './styled';
import getContactIdFromObjectID from 'helpers/getContactIdFromObjectID';
import { useParams } from 'react-router-dom';
import { Contact } from '@transactional-message-tool/shared';
import getInitials from 'helpers/getInitials';

interface ContactHit extends BasicDoc {
    firstName: string;
    lastName: string;
}

interface HitComponentI {
    hit: Hit<ContactHit>;
    noMessageInfo?: boolean;
}

const HitComponent = ({ hit, noMessageInfo }: HitComponentI) => {
    const contactId = getContactIdFromObjectID(hit.objectID);
    const { appId, groupId } = useParams();
    const [doc] = useDoc<{ id: string; data: Contact }>({
        path: `/apps/${appId}/groups/${groupId}/contacts/${contactId}`,
        disabled: noMessageInfo,
    });
    const name = `${hit.firstName} ${hit.lastName}`;
    return (
        <ContactItem
            contactId={contactId}
            name={
                <>
                    <StyledHighlight attribute="firstName" hit={hit} />
                    {` `}
                    <StyledHighlight attribute="lastName" hit={hit} />
                </>
            }
            initials={getInitials(name)}
            phoneNumber={<StyledHighlight attribute="phoneNumber" hit={hit} />}
            lastMessageDate={doc?.data.lastMessage?.createdAt.toDate()}
            lastMessage={doc?.data.lastMessage?.text}
            key={hit.objectID}
        />
    );
};

HitComponent.defaultProps = {
    noMessageInfo: false,
};

interface ReadFromAlgoliaProps {
    noMessageInfo?: boolean;
}
const readFromAlgolia = ({ noMessageInfo }: ReadFromAlgoliaProps) => {
    return (
        <Hits<ContactHit>
            hitComponent={({ hit }) => <HitComponent hit={hit} noMessageInfo={noMessageInfo} />}
        />
    );
};

export default readFromAlgolia;
