import { ReactNode } from 'react';
import { Container, InnerContainer, TopIcon, TopIconContainer, Content } from './styled';
import TopBar from './TopBar';

interface Props {
    children: ReactNode;
    hideYourPhoneNumber?: boolean;
}

const IPhone = ({ children, hideYourPhoneNumber }: Props) => {
    return (
        <Container>
            <InnerContainer>
                <TopIconContainer>
                    <TopIcon />
                </TopIconContainer>
                <TopBar hideYourPhoneNumber={hideYourPhoneNumber} />
                <Content>{children}</Content>
            </InnerContainer>
        </Container>
    );
};

export default IPhone;
