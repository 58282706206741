import { useState, useRef, useEffect } from 'react';

const useShowScrollDownButton = (atBottom: boolean) => {
    const [showButton, setShowButton] = useState(false);
    const showButtonTimeoutRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        return () => {
            clearTimeout(showButtonTimeoutRef.current);
        };
    }, []);

    useEffect(() => {
        clearTimeout(showButtonTimeoutRef.current);
        if (!atBottom) {
            showButtonTimeoutRef.current = setTimeout(() => setShowButton(true), 500);
        } else {
            setShowButton(false);
        }
    }, [atBottom, setShowButton]);

    return showButton;
};

export default useShowScrollDownButton;
