import { useState, SyntheticEvent } from 'react';
import { Media } from '@twilio/conversations';
import { Avatar, RenderIf, Spinner } from 'react-rainbow-components';
import { FormattedDate } from 'react-intl';
import getInitials from 'helpers/getInitials';
import {
    Item,
    MessageText,
    AuthorContainer,
    AuthorName,
    Time,
    MessageContainer,
    StyledImage,
} from '../styled';
import { Message } from '../types';
import { useQuery } from 'react-query';

const useMedia = (media?: Media) => {
    return useQuery(media?.sid || '', () => media?.getContentTemporaryUrl(), {
        enabled: Boolean(media),
    });
};

const MediaContent = ({ attachedMedia }: { attachedMedia: Media[] | null }) => {
    const [dimensions, setDimensions] = useState({
        height: 212,
        width: 212,
    });
    const { data, isLoading } = useMedia(attachedMedia?.[0]);
    const { height, width } = dimensions;

    if (isLoading) {
        return (
            <div style={{ height: 212, width: 212, position: 'relative' }}>
                <Spinner type="arc" variant="brand" />
            </div>
        );
    }

    if (data) {
        return (
            <StyledImage
                src={data}
                width={width}
                height={height}
                onLoad={(event: SyntheticEvent<HTMLImageElement>) => {
                    const target = event.target as HTMLImageElement;
                    setDimensions({
                        width: target.offsetWidth,
                        height: target.offsetHeight,
                    });
                }}
            />
        );
    }

    return null;
};

interface Props {
    message: Message;
}

const MessageBubble = ({ message }: Props) => {
    const { body, dateCreated, direction, authorName, type, attachedMedia } = message;

    return (
        <MessageContainer>
            <Item direction={direction}>
                {type === 'text' && <MessageText>{body}</MessageText>}
                {type === 'media' && <MediaContent attachedMedia={attachedMedia} />}
                {dateCreated && (
                    <Time>
                        <FormattedDate value={dateCreated} hour="numeric" minute="numeric" />
                    </Time>
                )}
            </Item>
            <RenderIf isTrue={direction === 'outgoing' && authorName}>
                <AuthorContainer>
                    <Avatar
                        size="x-small"
                        initials={getInitials(authorName)}
                        backgroundColor="#625B71"
                    />
                    <AuthorName>{authorName}</AuthorName>
                </AuthorContainer>
            </RenderIf>
        </MessageContainer>
    );
};

export default MessageBubble;
