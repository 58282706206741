import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: rgb(33, 31, 33);
    border-radius: 48px;
    border: 4px double rgb(33, 31, 33);
    padding: 0.6rem;
    max-width: 320px;
    min-width: 320px;
    height: 650px;
    margin: 0 auto;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out 0s;
    animation: 0.3s ease 0s 1 normal both running fadeInRight;
`;

export const InnerContainer = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 40px;
    height: 100%;
    position: relative;
    flex-grow: 1;
`;

export const TopIconContainer = styled.div`
    margin-top: -2px;
    text-align: center;
    height: 18px;
`;

export const TopIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="171" height="21" viewBox="0 0 171 21">
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="0.012"
                    x2="0.987"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#211f21"></stop>
                    <stop offset="1" stopColor="#211f21"></stop>
                </linearGradient>
            </defs>
            <g id="Group_1411" data-name="Group 1411" transform="translate(-76.95 -10)">
                <path
                    id="Rectangle_468"
                    data-name="Rectangle 468"
                    d="M0,0H171a0,0,0,0,1,0,0V5a16,16,0,0,1-16,16H16A16,16,0,0,1,0,5V0A0,0,0,0,1,0,0Z"
                    transform="translate(76.95 10)"
                    fill="url(#linear-gradient)"
                ></path>
            </g>
        </svg>
    );
};

export const Content = styled.div`
    width: 100%;
    flex: 1 1 0%;
    overflow: auto;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    background-color: white;
    border-radius: 0 0 40px 40px;
`;
