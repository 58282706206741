import isLessThanAWeekAgo from './isLessThanAWeekAgo';
import isSameDay from './isSameDay';

const formatDate = (date?: Date | null) => {
    if (date) {
        if (isLessThanAWeekAgo(date)) {
            const today = new Date();
            if (isSameDay(date, today)) {
                return 'Today';
            }
            const yesterday = new Date(today.setDate(today.getDate() - 1));
            if (isSameDay(date, yesterday)) {
                return 'Yesterday';
            }
            return new Intl.DateTimeFormat('en-US', {
                weekday: 'long',
            }).format(date);
        }
        return new Intl.DateTimeFormat('en-US', {
            day: '2-digit',
            month: 'short',
            weekday: 'short',
        }).format(date);
    }
    return '';
};

export default formatDate;
