import BackendClient from 'data/services/axios';

interface Params {
    appId: string;
    token: string;
}

const authenticateWidget = async (params: Params) => {
    const response = await BackendClient.get('/widget/auth', {
        params,
    });
    return response.data;
};

export default authenticateWidget;
