import { useCollection } from '@rainbow-modules/firebase-hooks';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { query, where, limit } from 'firebase/firestore';

const Root = () => {
    const { appId, groupId } = useParams();
    const [data] = useCollection({
        path: `/apps/${appId}/groups/${groupId}/contacts`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        query: (ref) => query(ref, where('hasNewMessage', '==', true), limit(21)),
    });
    useEffect(() => {
        const message = {
            eventName: 'rt-update-new-messages',
            count: data.length,
            hasMore: data.length === 21,
        };
        window.parent.postMessage(message, '*');
    }, [data.length]);

    return <Outlet />;
};

export default Root;
