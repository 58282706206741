import { Group } from '@transactional-message-tool/shared';
import useQuery from './useQuery';

const useMyGroup = () => {
    return useQuery<undefined, Group>({
        path: '/group',
    });
};

export default useMyGroup;
