/* eslint-disable react/prop-types */
// import { MagnifyingGlass } from '@rainbow-modules/icons';
import { ChangeEvent, useState } from 'react';
import { RenderIf } from 'react-rainbow-components';
import {
    Container,
    Header,
    Title,
    Content,
    SearchBoxStyled,
    Row,
    StyledButtonIcon,
} from './styled';
import { InstantSearch } from 'react-instantsearch-dom';
import useAlgoliaClient from '../../hooks/useAlgoliaClient';
import ReadFromAlgolia from './readFromAlgolia';
import ReadFromFirestore from './readFromFirestore';
import { Edit } from '../../components/icons';
import { Outlet, useNavigate } from 'react-router-dom';
import SearchEmptyMessage from '../../components/AlgoliaSearchEmptyMessage';

const Contacts = () => {
    const client = useAlgoliaClient();
    const [query, setQuerry] = useState('');
    const navigate = useNavigate();
    const updateQuerry = (event: unknown): void => {
        setQuerry((event as ChangeEvent<HTMLInputElement>).target.value);
    };

    if (client) {
        return (
            <InstantSearch indexName="contacts" searchClient={client}>
                <Container>
                    <Header>
                        <Row>
                            <Title>Messages</Title>
                            <StyledButtonIcon
                                variant="base"
                                icon={<Edit />}
                                onClick={() => navigate('search')}
                            />
                        </Row>
                        <SearchBoxStyled onChange={updateQuerry} />
                    </Header>
                    <Content>
                        <RenderIf isTrue={query.length === 0}>
                            <ReadFromFirestore />
                        </RenderIf>
                        <RenderIf isTrue={query.length > 0}>
                            <ReadFromAlgolia />
                            <SearchEmptyMessage />
                        </RenderIf>
                    </Content>
                    <Outlet />
                </Container>
            </InstantSearch>
        );
    }
    return null;
};

export default Contacts;
