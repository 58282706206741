import { useParams } from 'react-router-dom';
import useContact from 'data/hooks/useContact';
import getInitials from 'helpers/getInitials';
import { LoadingShape } from 'react-rainbow-components';
import {
    Header,
    ArrowIcon,
    StyledAvatar,
    ContactName,
    ContactPhoneNumber,
    HeaderLink,
} from './styled';

const ChatHeader = () => {
    const { appId, groupId, contactId } = useParams();
    const [contact, isLoading] = useContact({ appId, groupId, contactId });

    if (!isLoading && contact) {
        const { firstName, lastName, phoneNumberParts } = contact;
        const fullName = `${firstName} ${lastName}`;

        return (
            <Header>
                <HeaderLink to={`/app/${appId}/groups/${groupId}/contacts`}>
                    <ArrowIcon />
                    <StyledAvatar initials={getInitials(fullName)} />
                    <div>
                        <ContactName>{fullName}</ContactName>
                        <ContactPhoneNumber>{phoneNumberParts?.formattedNumber}</ContactPhoneNumber>
                    </div>
                </HeaderLink>
            </Header>
        );
    }

    return (
        <Header>
            <HeaderLink to={`/app/${appId}/groups/${groupId}/contacts`}>
                <ArrowIcon />
                <StyledAvatar />
                <div>
                    <ContactName>
                        <LoadingShape className="rainbow-p-right_large" />
                    </ContactName>
                    <ContactPhoneNumber>
                        <LoadingShape className="rainbow-m-top_x-small" />
                    </ContactPhoneNumber>
                </div>
            </HeaderLink>
        </Header>
    );
};

export default ChatHeader;
