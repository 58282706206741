import styled from 'styled-components';

export const Container = styled.div`
    padding: 4px 0.5rem 0;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const NumberContainer = styled.div`
    background-color: #e5e3e8;
    border-radius: 4px;
    padding: 4px 16px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Label = styled.div`
    font-family: Lato, Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 200;
`;

export const Number = styled.div`
    font-family: Lato, Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 600;
`;
