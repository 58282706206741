import PropTypes from 'prop-types';
import { IconProps } from './types';

const Close = ({ title, className, style }: IconProps) => {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            style={style}
        >
            <title>{title}</title>
            <path
                d="M18 6L6 18"
                stroke="#A3A8A9"
                strokeOpacity="0.9"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6 6L18 18"
                stroke="#A3A8A9"
                strokeOpacity="0.9"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

Close.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

Close.defaultProps = {
    className: undefined,
    style: undefined,
    title: 'Close',
};

export default Close;
