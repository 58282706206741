import { ButtonIcon } from 'react-rainbow-components';
import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
`;

export const StyledCloseButtonIcon = styled(ButtonIcon)`
    position: absolute;
    top: 2px;
    right: 5px;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`;
